<template>
  <div id="text-content">
    <div class="text text-state0" v-if="textState == 0">
      <div class="text-anim text-state0-anim">
        <div class="text-solid text-state0-solid">
          <div id="text1">Oh, hi there!</div>
        </div>
      </div>
    </div>
    <div class="text text-state1" v-if="textState == 1">
      <div class="text-anim text-state1-anim">
        <div class="text-solid text-state1-solid">
          <div id="text1"> Welcome to my website.</div>
        </div>
      </div>
    </div>
    <div class="text text-state1-2" v-if="textState == 1">
      <div class="text-anim text-state1-2-anim">
        <div class="text-solid text-state1-2-solid">
          <div id="text1">My name is Niko and I am a web developer.</div>
        </div>
      </div>
    </div>
    <div class="text text-state2" v-if="textState == 2">
      <div class="text-anim text-state2-anim">
        <div class="text-solid text-state2-solid">
          <div id="text1">Check out my projects.</div>
        </div>
      </div>
    </div>
    <div class="text text-state3" v-if="textState == 3">
      <div class="text-anim text-state3-anim">
        <div class="text-solid text-state3-solid">
          <div id="text1">And if you like what you see</div>
        </div>
      </div>
    </div>
    <div class="text text-state3-2" v-if="textState == 3">
      <div class="text-anim text-state3-2-anim">
        <div class="text-solid text-state3-2-solid">
          <div id="text1">feel free to contact me!</div>
        </div>
      </div>
    </div>
    <div class="text text-state-end" v-if="textState == 3">
      <div class="text-anim text-state-end-anim">
        <div class="text-solid text-state-end-solid">
          <div id="text1">feel free to contact me!</div>
        </div>
      </div>
    </div>
    <div id="text-content-perspective1"><div id="text-content-top"></div></div>
    <div id="text-content-perspective2"><div id="text-content-top2"></div></div>
    <div id="text-content-3d"></div>
  </div>
</template>

<script>
export default {
  name: "Textbox",
  props: {
    textState: {
      type: Number,
      required: true,
    },
  },

  components: {},

  methods: {},

  data: function () {
    return {};
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";

#text-content {
  position: relative;
  height: $contentheightsmall;
  width: 100%;
  background: $textboxcolorbg;
  border: solid 2px $textboxcolor;
  border-radius: 0.5em;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .text {
    position: relative;
    overflow: hidden;
    color: white;
    height: $chatfontsize + 0.9vh;
    font-size: $chatfontsize;
    font-family: $chatfont;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .text-anim {
    position: absolute;
    overflow: hidden;
    left: 0;
    height: $chatfontsize + 0.9vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .text-solid {
      position: absolute;
      top: 0.3vh;
      height: 3vh;
      left: 0;
    }
  }

  .text-state0 {
    width: 10.7vh;
    .text-state0-anim {
      width: 10vh;
      opacity: 0;
      animation: typewriter 0.6s linear $textboxbubblestime2 forwards,
        blink-caret 0.45s  $textboxbubblestime2 linear infinite;
      .text-state0-solid {
        width: 10vh;
      }
    }
  }
  .text-state1 {
    width: 20.3vh;
    .text-state1-anim {
      width: 20vh;
      animation: typewriter 1.6s linear forwards, blink-caret 0.45s linear infinite;
      .text-state1-solid {
        width: 20vh;
      }
    }
  }
  .text-state1-2 {
    width: 36.3vh;
    .text-state1-2-anim {
      opacity: 0;
      width: 36vh;
      animation: typewriter 1.6s linear forwards 1.6s, blink-caret 0.45s linear infinite 1.6s;
      .text-state1-2-solid {
        width: 36vh;
      }
    }
  }
  .text-state2 {
    width: 21.3vh;
    .text-state2-anim {
      width: 21vh;
      animation: typewriter 1.3s linear, blink-caret 0.45s linear infinite;
      .text-state2-solid {
        width: 21vh;
      }
    }
  }
  .text-state3 {
    width: 27.3vh;
    .text-state3-anim {
      width: 27vh;
      animation: typewriter 1.9s linear, blink-caret 0.45s linear 4.2;
      .text-state3-solid {
        width: 27vh;
      }
    }
  }
  .text-state3-2 {
    width: 25.3vh;
    .text-state3-2-anim {
      opacity: 0;
      width: 25vh;
      animation: typewriter 1.9s linear 1.9s forwards,
blink-caret 0.45s linear infinite 1.9s;
      .text-state3-2-solid {
        width: 25vh;
      }
    }
  }

  #text-content-perspective1 {
    position: absolute;
    perspective: 100px;
    left: 50%;
    top: 0;
    #text-content-top {
      position: absolute;
      transform: rotateX(50deg);
      top: -2.4vh;
      left: calc(50% - 10vh - 1.3px);
      background: rgba(255, 255, 255, 0.068);
      border: solid 2.6px rgb(255, 21, 52);
      //   height: 1.3vh;
      width: 20vh;
      animation: enter_top $textboxbubblestime $contenttransitiontime forwards;
      height: 0;
      opacity: 0;
    }
  }
  #text-content-perspective2 {
    position: absolute;
    perspective: 100px;
    left: 50%;
    top: 0;
    #text-content-top2 {
      position: absolute;
      transform: rotateX(60deg);
      top: -5.9vh;
      left: calc(50% - 1vh - 1.3px);
      background: rgba(255, 255, 255, 0.068);
      border: solid 2.6px rgb(255, 21, 52);
      //   height: 4.5vh;
      width: 2vh;
      animation: enter_top2 $textboxbubblestime $textboxbubblestime2 forwards;
      height: 0;
      opacity: 0;
    }
  }

  #text-content-3d {
    position: absolute;
    bottom: -0.8vh;
    height: $contentheightsmall;
    width: 100%;
    border-radius: 0.5em;
    //   transform: translate(0px, 0.4vh);
    z-index: -1;
    border-bottom: solid 2px $textboxcolor;
  }
}
@keyframes typewriter {
  0% {
    opacity: 1;
    width: 0;
  }
  100% {
    // width: 60vw;
    opacity: 1;
    width: 100%;
  }
}

@keyframes typewriter_line1 {
  0% {
    opacity: 1;
    width: 0;
  }
  100% {
    // width: 60vw;
    opacity: 1;
    width: 100%;
  }
}
@keyframes typewriter_line2 {
  0% {
    opacity: 1;
    width: 0;
  }
  100% {
    // width: 60vw;
    opacity: 1;
    width: 100%;
  }
}
@keyframes blink-caret {
  0% {
    border-right: solid 0px rgb(255, 0, 43);
    // opacity: 1;
  }
  50% {
    border-right: solid 0px rgb(255, 0, 43);
    // opacity: 1;
  }
  51% {
    border-right: solid 2px rgb(255, 0, 43);
    opacity: 1;
  }
  100% {
    border-right: solid 2px rgb(255, 0, 43);
    opacity: 1;
  }
}
@keyframes enter_top {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 1.3vh;
    opacity: 1;
  }
}
@keyframes enter_top2 {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 4.5vh;
    opacity: 1;
  }
}
</style>