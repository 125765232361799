<template>
  <div id="main">
    <div id="background"></div>

    <div class="preload-content">
      <img src="../assets/head/head.png" />
      <img src="../assets/head/sleep.png" />
      <img src="../assets/head/blink.png" />
      <img src="../assets/head/talk.png" />
      <img src="../assets/background.jpg" />
      <img src="../assets/close.png" />
      <img src="../assets/csharp.png" />
      <img src="../assets/css.png" />
      <img src="../assets/html.png" />
      <img src="../assets/invoicemaker.png" />
      <img src="../assets/js.png" />
      <img src="../assets/laravel.png" />
      <img src="../assets/mobday.png" />
      <img src="../assets/mysql.png" />
      <img src="../assets/next.png" />
      <img src="../assets/open.png" />
      <img src="../assets/outfitrecipes.png" />
      <img src="../assets/visualstudio.png" />
      <img src="../assets/tailwindcss.png" />
      <img src="../assets/filament.png" />
      <img src="../assets/fake_clothing.png" />
      <img src="../assets/vue.png" />
      <img src="../assets/me.png" />
      <img src="../assets/projects.png" />
      <img src="../assets/about.png" />
      <img src="../assets/contact.png" />
      <img src="../assets/facts/drawing.png" />
      <img src="../assets/facts/electronics.png" />
      <img src="../assets/facts/oderso.png" />
      <img src="../assets/facts/todo.png" />
      <img src="../assets/facts/water.png" />
      <img src="../assets/facts/website.png" />
      <img src="../assets/facts/wizard.png" />
    </div>
    <div id="perspective">
      <Headbox
        @button-click="btnManager"
        :loaded="assetsLoaded"
        :scene="scene"
        :textState="textState"
        :speaking="speaking"
      />
      <Contentbox
        @button-click="btnManager"
        :scene="scene"
        :textState="textState"
        :sceneContent="sceneContent"
      />
    </div>
  </div>
</template>

<script>
import Headbox from "../components/Headbox.vue";
import Contentbox from "../components/Contentbox.vue";
export default {
  name: "Main",
  props: {},

  components: {
    Headbox,
    Contentbox,
  },

  methods: {
    btnManager(btnContent) {
      const delay = (ms) => new Promise((res) => setTimeout(res, ms));
      const mainSceneSwitch = async () => {
        await delay(70);
        this.scene = "main";
      };
      const endSpeaking = async (no) => {
        if(this.textState==0){
            no = 1350;
        }
         if(this.textState==1){
            no = 3200;
        }
         if(this.textState==2){
            no = 1300;
        }
         if(this.textState==3){
            no = 3800;
        }
        
        await delay(no);
        this.speaking = false;
      };

      if (document.getElementById("scene-transition")) {
        document
          .getElementById("scene-transition")
          .addEventListener("animationend", () => {
            return;
          });
      }

      let head = document.getElementById("head"); 

      if (btnContent == "about") {
        this.sceneContent = true;
        this.scene = "about";
      }
      if (btnContent == "projects") {
        this.sceneContent = true;
        this.scene = "projects";
      }
      if (btnContent == "contact") {
        this.sceneContent = true;
        this.scene = "contact";
      }
      //CONTENTBUTTON TEXT
      if (
        btnContent == "content" &&
        (this.scene == "main_first" || this.scene == "main")
      ) {
        if (this.textState < 3) {
          this.textState++;
          this.speaking = true;
          endSpeaking();
        }
      }

      //CONTENTBUTTON START
      if (btnContent == "content" && this.scene == "loaded") {
        this.sceneContent = false;
        this.scene = "main_first";
        head.style.animation = "idle 3s infinite";
        this.speaking = true;
        endSpeaking();
      }

      //CONTENTBUTTON BACK TO MAIN
      if (
        btnContent == "content" &&
        this.scene != "loaded" &&
        this.scene != "main" &&
        this.scene != "main_first"
      ) {
        // let sceneTransition =  document.getElementById("scene-transition");

        this.sceneContent = false;
        
        mainSceneSwitch();
        this.speaking = true;
        endSpeaking();
      }
      //CONTENTBUTTON END
    },

    loadAssets() {
      // document.getElementById("preload-face").addEventListener("load", () => {
      //   this.headLoaded = true;
      // });
       
      window.addEventListener("load", () => {
        
        // console.log("done");
        this.assetsLoaded = true;
        this.scene = "loaded";
      });
    },
  },

  data: function () {
    return {
      tmp: true,
      assetsLoaded: false,
      scene: "loading", //loading,loaded,main,about,projects,contact
      sceneContent: false,
      textState: 0,
      speaking: false,
    };
  },

  created() {
    this.loadAssets();
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";

#main {
  //   position: fixed;
  height: 100vh;
  width: 100vw;
  // background: rgb(10, 10, 26);
  display: flex;
  justify-content: center;
  align-items: center;
  perspective: 1000px;

  #background {
    position: absolute;
    background: url("../assets/background.jpg");
    background-size: auto 100vh;
    background-position: center;
    height: 100vh;
    width: 100vw;
  }

  #perspective {
    transform: rotateX(13deg);
    // height: 100%;
    width: 90vw;
    margin-bottom: 3vh;
  }

  .preload-content {
    opacity: 0%;
    position: absolute;
    top: -100vh;
    z-index: -100000;
  }

  #preload-face {
    opacity: 0%;
    position: absolute;
    top: -100vh;
    z-index: -100000;
  }
}

@media (min-width: 700px) {
  #main {
    height: 99vh;
    width: 70vw;
  }
}
@media (min-width: 1100px) {
  #main {
    height: 99vh;
    width: 50vw;
  }
}
@media (min-width: 1600px) {
  #main {
    height: 99vh;
    width: 40vw;
  }
}
</style>