<template>
  <div id="projects-content">
    <transition name="project_transition">
      <div id="scene-transition" v-if="sceneContent && transitionHelper">
        <h2>PROJECTS</h2>

        <div id="project-divs">
          <div class="project-wrapper">
            <div id="fakeclothing" class="project-container">
              <div class="project-title" v-on:click="openProject('fakeclothing')">
                <img src="../assets/fake_clothing.png" />
                <div class="dropdown-button">
                  <img v-if="project == 'fakeclothing'" src="../assets/close.png" /><img v-else
                    src="../assets/open.png" />
                </div>
              </div>

              <transition name="project_content_transition">
                <div id="outfitrecipes-content" class="project-content" v-if="project == 'fakeclothing'">
                  <div class="project-date">developed in 2023</div>
                  fakeclothing.de serves as Cassandra Knodel's portfolio website, showcasing her work as a Fashion Design
                  student.
                  <br />
                  Developed using Laravel and Tailwind CSS, the site includes an admin panel powered by the
                  filament framework. This panel enables Cassandra to easily manage page content, such as projects,
                  images, and descriptions
                  <br />
                  visit at:
                  <br />
                  <a href="https://fakeclothing.de" target="_blank">https://fakeclothing.de</a>
                  <br />

                  <div class="img-container">
                    <img src="../assets/laravel.png" />
                    <img src="../assets/filament.png" />
                    <img src="../assets/tailwindcss.png" />
                  </div>
                </div>
              </transition>
            </div>
            <div class="project-container-3d"></div>
          </div>
          <div class="project-wrapper">
            <div id="outfitrecipes" class="project-container">
              <div class="project-title" v-on:click="openProject('outfitrecipes')">
                <img src="../assets/outfitrecipes.png" />
                <div class="dropdown-button">
                  <img v-if="project == 'outfitrecipes'" src="../assets/close.png" /><img v-else
                    src="../assets/open.png" />
                </div>
              </div>

              <transition name="project_content_transition">
                <div id="outfitrecipes-content" class="project-content" v-if="project == 'outfitrecipes'">
                  <div class="project-date">developed in 2021</div>
                  outfit.recipes is a website I designed to curate and share outfit inspirations.
                  <br />
                  It features an admin panel and login system allowing me to create and oversee the showcased outfits.
                  These outfits are then displayed on the main page feed and can be searched, filtered and sorted.
                  <br />
                  Visit at:
                  <br />
                  <a href="https://outfit.toadally.online" target="_blank">https://outfit.toadally.online</a>
                  <br />
                  <br />

                  <div class="img-container">
                    <img src="../assets/laravel.png" />
                    <img src="../assets/vue.png" />
                    <img src="../assets/mysql.png" />
                  </div>
                </div>
              </transition>
            </div>
            <div class="project-container-3d"></div>
          </div>

          <div class="project-wrapper">
            <div id="birthday" class="project-container">
              <div class="project-title" v-on:click="openProject('birthday')">
                <img src="../assets/mobday.png" />
                <div class="dropdown-button">
                  <img v-if="project == 'birthday'" src="../assets/close.png" /><img v-else src="../assets/open.png" />
                </div>
              </div>

              <transition name="project_content_transition">
                <div id="birthday-content" class="project-content" v-if="project == 'birthday'">
                  <div class="project-date">developed in 2020</div>
                  Mo's Birthday Party is a small web game I created to celebrate my friend's birthday. 
                  <br />
                  Your decisions in
                  the game unlock achievements, which are saved as cookies.
                  <br />visit at:
                  <br />
                  <a href="https://mos-birthday.toadally.online" target="_blank">https://mos-birthday.toadally.online</a>
                  <br />
                  <br />

                  <div class="img-container">
                    <img src="../assets/html.png" />
                    <img src="../assets/js.png" />
                    <img src="../assets/css.png" />
                  </div>
                </div>
              </transition>
            </div>
            <div class="project-container-3d"></div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="projects_3d_transistion">
      <div id="projects-content-3d" v-if="scene == 'projects'"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "Projects",
  props: {
    scene: {
      type: String,
      required: true,
    },
    sceneContent: {
      type: Boolean,
      required: true,
    },
  },

  components: {},

  methods: {
    openProject(project) {
      if (this.project == "") {
        this.project = project;
      } else if (this.project == project) {
        this.project = "";
      } else {
        this.project = project;
      }
    },
  },

  data: function () {
    return {
      project: "fakeclothing",
      transitionHelper: false,
    };
  },
  mounted() {
    this.transitionHelper = true;
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";

#projects-content {
  position: relative;
  height: $contentheightfull;
  width: 100%;
  background: $projectscolorbg;
  border: solid 2px $projectscolor;
  border-radius: 0.5em;
  color: white;

  h2 {
    font-family: $titlefont;
    font-size: 4vh;
    -webkit-text-stroke-width: 0.4px;
    -webkit-text-stroke-color: $projectscolor;
    text-shadow: 0px 0px 7px rgba(110, 255, 224, 0.726);
  }

  #projects-content-3d {
    position: absolute;
    bottom: -0.8vh;
    height: $contentheightsmall;
    width: 100%;
    border-radius: 0.5em;
    z-index: -1;
    border-bottom: solid 2px $projectscolor;
    animation: enter_projects3d 0.6s;

  }

  #project-divs {
    width: 100%;
    // height: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-family: $textfont;

    .project-wrapper {
      position: relative;
      width: 90%;

      .project-container {
        margin-top: 2vh;
        width: 100%;
        border: solid 2px $projectscolor;
        background: rgba(13, 34, 36, 0.534);
        border-radius: 0.5em;
        
        .project-date {
          color: aqua;
          opacity: 0.5;
          font-size: 15px;
          padding-bottom: 4px;
        }

        .project-title {
          position: relative;
          padding: 2vh;
          display: flex;
          justify-content: center;
          height: 3vh;

          img {
            position: absolute;
            height: 6.5vh;
            top: 0;
          }

          &:hover {
            cursor: pointer;
          }

          .dropdown-button {
            position: absolute;
            right: 2vh;
            bottom: 2px;

            img {
              position: relative;
              height: 5vh;
            }
          }
        }

        #outfitrecipes-content {
          height: 37vh;
        }

        #birthday-content {
          height: 28vh;
        }

        #invoicemaker-content {
          height: 26vh;
        }

        .project-content {
          height: auto;
          font-size: 1.8vh;
          padding-right: 2vh;
          padding-left: 2vh;

          // padding-bottom: 2vh;
          a {
            text-decoration: none;
            color: cyan;
          }

          .img-container {
            // width: 40vh;
            // padding: 2vh;
            padding-top: 2vh;
            padding-bottom: 2vh;
            height: 9vh;
            display: flex;
            align-items: center;
            justify-content: space-around;

            img {
              height: 9vh;
            }
          }
        }
      }

      .project-container-3d {
        position: absolute;
        bottom: -0.5vh;
        left: 2px;
        height: 5vh;
        width: 100%;
        border-radius: 0.5em;
        z-index: -1;
        border-bottom: solid 2px $projectscolor;
      }
    }
  }
}

.project_transition-enter-active {
  opacity: 0;
  animation: scene_content_enter 0.2s 0.2s;
}

.project_transition-leave-active {
  animation: scene_content_leave 0.25s;
}

.project_content_transition-enter-active {
  opacity: 0;
  animation: project_content_enter 0.3s;
}

.project_content_transition-leave-active {
  animation: project_content_leave 0.3s;
}

@keyframes project_content_leave {
  0% {}

  100% {
    height: 0;
    transform: scaleY(0) translateY(-20vh);
  }
}

@keyframes project_content_enter {
  0% {
    height: 0;
    transform: scaleY(0) translateY(-20vh);
  }

  100% {
    opacity: 1;
  }
}

@keyframes scene_content_leave {
  0% {
    // border: solid 3px white;
  }

  100% {
    //  height:0;
    transform: scaleY(0) scaleX(1.1);
  }
}

@keyframes scene_content_enter {
  0% {
    transform: scaleY(0) scaleX(1.1);
  }

  100% {
    opacity: 1;
  }
}

@keyframes enter_projects3d {
  0% {
    border-bottom: solid 2px $textboxcolor;
  }

  100% {
    border-bottom: solid 2px $projectscolor;
  }
}

@media only screen and (min-width: 250px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 37vh;
          }

          #birthday-content {
            height: 29vh;
          }

          #invoicemaker-content {
            height: 32vh;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 350px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 34vh;
          }

          #birthday-content {
            height: 29vh;
          }

          #invoicemaker-content {
            height: 29vh;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 405px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 33vh;
          }

          #birthday-content {
            height: 29vh;
          }

          #invoicemaker-content {
            height: 29vh;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 590px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 28vh;
          }

          #birthday-content {
            height: 26vh;
          }

          #invoicemaker-content {
            height: 26vh;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 700px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 31vh;
          }

          #birthday-content {
            height: 27vh;
          }

          #invoicemaker-content {
            height: 28vh;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 900px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 30vh;
          }

          #birthday-content {
            height: 27vh;
          }

          #invoicemaker-content {
            height: 25vh;
          }
        }
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  #projects-content {
    #project-divs {
      .project-wrapper {
        .project-container {
          #outfitrecipes-content {
            height: 30vh;
          }

          #birthday-content {
            height: 25vh;
          }

          #invoicemaker-content {
            height: 24vh;
          }
        }
      }
    }
  }
}
</style>