<template>
  <!-- <transition name="headbox_transition"> -->
  <!-- <div
      id="headbox"
      v-if="scene == 'loading' || scene == 'loaded' || scene == 'main' || scene == 'main_first'"
    > -->
  <div id="headbox">
    <transition name="headbox_transition">
      <div
        id="headbox_size"
        v-if="
          scene == 'loading' ||
          scene == 'loaded' ||
          scene == 'main' ||
          scene == 'main_first'
        "
      ></div>
    </transition>
    <transition name="scene_buttons_transition">
      <div
        id="head_container"
        v-if="
          scene == 'loading' ||
          scene == 'loaded' ||
          scene == 'main' ||
          scene == 'main_first'
        "
      >
        <div id="head">
          <img
            v-if="speaking == true"
            class="speaking"
            src="../assets/head/talk.png"
          />
          <img
            class="blink"
            v-if="scene == 'main' || scene == 'main_first'"
            src="../assets/head/blink.png"
          />
          <img
            v-if="scene == 'loading' || scene == 'loaded'"
            src="../assets/head/sleep.png"
          />
          <img
            v-if="scene == 'main' || scene == 'main_first'"
            src="../assets/head/head.png"
          />
        </div>
      </div>
    </transition>
    <!-- <div class="loading-text" v-if="scene == 'loading'">loading...</div> -->
    <div class="loading-text" v-if="scene == 'loading'">
      loading<a class="loading-dot1">.</a><a class="loading-dot2">.</a
      ><a class="loading-dot3">.</a>
    </div>
    <div class="zzz" v-if="scene == 'loading' || scene == 'loaded'">
      <div class="z1">z</div>
      <div class="z2">z</div>
      <div class="z3">z</div>
    </div>

    <div class="buttons">
      <transition name="scene_buttons_transition">
        <div
          id="about-container"
          v-on:click="$emit('button-click', 'about')"
          class="button"
          v-if="(scene == 'main' || scene == 'main_first') && textState >= 1"
        >
          <img src="../assets/about.png" />
          <div id="about" class="button"></div>
          <div id="about-3d" class="button about-3d"></div>
          <div class="scene-button-text">ABOUT ME</div>
        </div>
      </transition>
      <transition name="scene_buttons_transition">
        <div
          id="projects-container"
          v-on:click="$emit('button-click', 'projects')"
          class="button"
          v-if="(scene == 'main' || scene == 'main_first') && textState >= 2"
        >
          <img src="../assets/projects.png" />
          <div id="projects" class="button"></div>
          <div id="projects-3d" class="button projects-3d"></div>
          <div class="scene-button-text">PROJECTS</div>
        </div>
      </transition>
      <transition name="scene_buttons_transition">
        <div
          id="contact-container"
          v-on:click="$emit('button-click', 'contact')"
          class="button"
          v-if="(scene == 'main' || scene == 'main_first') && textState >= 3"
        >
          <img src="../assets/contact.png" />
          <div id="contact" class="button"></div>
          <div id="contact-3d" class="button contact-3d"></div>
          <div class="scene-button-text">CONTACT</div>
        </div>
      </transition>
    </div>
  </div>
  <!-- </transition> -->
</template>

<script>
export default {
  name: "Headbox",
  props: {
    loaded: {
      type: Boolean,
      required: true,
    },
    speaking: {
      type: Boolean,
      required: true,
    },
    scene: {
      type: String,
      required: true,
    },
    textState: {
      type: Number,
      required: true,
    },
  },

  components: {},

  methods: {},

  data: function () {
    return {
      tmp: true,
      headLoaded2: false,
    };
  },
  mounted() {
    let head = document.getElementById("head");
    head.style.animation = "sleeping 3s infinite";
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";
#headbox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  #headbox_size {
    height: $headboxheight;
  }
  #head {
    width: $headsize;
    height: $headsize;
    animation: idle 3s infinite;
    .speaking {
      position: absolute;
      animation: talk 0.3s infinite;
    }
    .blink {
      position: absolute;
      animation: blink 4s infinite;
    }

    img {
      width: $headsize;
    }
  }

  .buttons {
    .button {
      position: absolute;
      height: $scenebuttonsize;
      width: $scenebuttonsize;

      border-radius: 50%;
      transition: 0.2s;
      &:hover {
        transition: 0.2s;
        transform: scale(0.9);
      }
      .scene-button-text {
        position: absolute;
        font-family: $titlefont;
        font-size: 2.5vh;
        color: white;
        text-shadow: $aboutcolor 0px 0px 0.7vh;
        left: -4vh;
        top: 10vh;
        width: 16vh;
      }
    }

    #about-container {
      position: absolute;
      top: 0;
      left: calc(50% - 4vh);
      img {
        position: absolute;
        // left:-0.5vh;
        // top:0.25vh;
        left: -0.25vh;
        top: 0.5vh;
        z-index: 1;
        height: $scenebuttonsize + 0.5vh;
      }

      #about {
        position: absolute;
        // left:50%;
        border: solid 0.7vh $aboutcolor;
        transform: translateX(-0.7vh);
        // background: rgba(252, 255, 80, 0.267);
        box-shadow: $aboutcolorbg 0px 0.5vh 0px;
        z-index: 2;

        &:hover {
          cursor: pointer;
          // box-shadow: rgba(255, 230, 0, 0.747) 0px 0vh 30px;
          transition: 0.2s;
          //  transform:translateY(1vh);
        }
      }
      .about-3d {
        //   height: 11.6vh;
        // width: 11.6vh;
        position: absolute;
        box-shadow: 0px 0px 15px rgba(251, 255, 0, 0.582);
        border: solid 0.5vh rgba(0, 0, 0, 0);
        transform: translateX(-0.5vh);
        z-index: -1;

        //   top:2vh;
        //   transform: translateX(-0.8vh);
        //   border-bottom: solid 0.4vh rgb(255, 251, 6);
        //     border-left: solid 0.4vh rgb(255, 251, 6);
        //       border-right: solid 0.4vh rgb(255, 251, 6);
        // background: rgba(252, 255, 80, 0.267);
      }
    }
    #projects-container {
      position: absolute;
      bottom: 6.5vh;
      left: 0;
      img {
        position: absolute;
        // left:-0.5vh;
        // top:0.25vh;
        left: -0.25vh;
        top: 0.5vh;
        z-index: 1;
        height: $scenebuttonsize + 0.5vh;
      }

      #projects {
        position: absolute;
        // left:50%;
        border: solid 0.7vh $projectscolor;
        transform: translateX(-0.7vh);
        // background: rgba(252, 255, 80, 0.267);
        box-shadow: $projectscolorbg 0px 0.5vh 0px;
        z-index: 2;

        &:hover {
          cursor: pointer;
          // box-shadow: rgba(255, 230, 0, 0.747) 0px 0vh 30px;
          transition: 0.2s;
          //  transform:translateY(1vh);
        }
      }
      .projects-3d {
        //   height: 11.6vh;
        // width: 11.6vh;
        position: absolute;
        box-shadow: 0px 0px 15px rgba(0, 174, 255, 0.582);
        border: solid 0.5vh rgba(0, 0, 0, 0);
        transform: translateX(-0.5vh);
        z-index: -1;
      }
    }

    #contact-container {
      position: absolute;
      bottom: 6.5vh;
      right: 0;
      img {
        position: absolute;
        // left:-0.5vh;
        // top:0.25vh;
        left: -0.25vh;
        top: 0.5vh;
        z-index: 1;
        height: $scenebuttonsize + 0.5vh;
      }

      #contact {
        position: absolute;
        // left:50%;
        border: solid 0.7vh $contactcolor;
        transform: translateX(-0.7vh);
        // background: rgba(252, 255, 80, 0.267);
        box-shadow: $contactcolorbg 0px 0.5vh 0px;
        z-index: 2;

        &:hover {
          cursor: pointer;
          // box-shadow: rgba(255, 230, 0, 0.747) 0px 0vh 30px;
          transition: 0.2s;
          //  transform:translateY(1vh);
        }
      }
      .contact-3d {
        //   height: 11.6vh;
        // width: 11.6vh;
        position: absolute;
        box-shadow: 0px 0px 15px $contactcolorbg;
        border: solid 0.5vh rgba(0, 0, 0, 0);
        transform: translateX(-0.5vh);
        z-index: -1;
      }
    }
  }
  .loading-text {
    color: white;

    position: absolute;
    bottom: 7vh;
    animation: loadingtext 4s infinite linear;

    .loading-dot1 {
      animation: loadingdot 2s infinite linear;
    }
    .loading-dot2 {
      animation: loadingdot 2s 0.7s infinite linear;
    }
    .loading-dot3 {
      animation: loadingdot 2s 1.4s infinite linear;
    }
  }

  .zzz {
    color: rgb(255, 78, 101);

    position: absolute;
    top: 19vh;
    right: 10vh;
    text-shadow: rgba(255, 55, 55, 0.733) 0px 0px 0.6vh;
    // animation: loadingzzz 3s infinite linear;

    .z1 {
      font-family: $textfont;

      transform: translate(0vh, 0vh);
      font-size: 2vh;
      animation: zzz1 3s infinite linear;
    }
    .z2 {
      font-family: $textfont;
      transform: translate(3vh, -6.5vh);
      font-size: 3.2vh;
      animation: zzz2 3s infinite linear;
    }
    .z3 {
      font-family: $textfont;
      transform: translate(1vh, -17.5vh);
      font-size: 5.2vh;
      animation: zzz3 3s infinite linear;
    }
  }
}
// .shrink {
//   animation: shrink 1s forwards;
// }
.headbox_transition-enter-active {
  animation: grow $contenttransitiontime;
}
.headbox_transition-leave-active {
  animation: shrink $contenttransitiontime;
}

.scene_buttons_transition-enter-active {
  animation: scene_buttons_enter $contenttransitiontime;
}
.scene_buttons_transition-leave-active {
  animation: scene_buttons_leave 0.5s;
}
@keyframes talk {
  0% {
    transform: scaleY(0.8) translateY(0.4vh);
  }
  50% {
    transform: scaleY(1.23);
  }
  100% {
    transform: scaleY(0.8) translateY(0.4vh);
  }
}
@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 0;
  }
  51% {
    opacity: 1;
  }
  53% {
    opacity: 1;
  }
  54% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}
@keyframes loadingtext {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes loadingzzz {
  // 0% {
  //   transform: translateY(0vh);
  // }
  // 100% {
  //   transform: translateY(-2vh);
  // }
  0% {
    transform: scale(1);
  }
  // 95% {
  //   transform: scale(1);
  // }
  100% {
    transform: scale(1.1);
  }
}
@keyframes loadingdot {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes zzz1 {
  0% {
    opacity: 1;
    transform: scale(1.2) translate(0vh, 0vh);
  }
  35% {
    opacity: 1;
    transform: scale(1.6) translate(0.2vh, -0.2vh);
  }
  40% {
    opacity: 0;
    transform: scale(1.6) translate(0.2vh, -0.2vh);
  }
  70% {
    opacity: 0;
  }
  90% {
    opacity: 0;
    transform: scale(1) translate(0vh, 0vh);
  }
  100% {
    opacity: 1;
    transform: scale(1.2) translate(0vh, 0vh);
  }
}
@keyframes zzz2 {
  0% {
    opacity: 0;
  }
  30% {
    opacity: 0;
    transform: scale(1) translate(3vh, -6.5vh);
  }
  35% {
    opacity: 1;
  }
  65% {
    opacity: 1;
    transform: scale(1.2) translate(3vh, -6.5vh);
  }
  70% {
    opacity: 0;
    transform: scale(1.2) translate(3vh, -6.5vh);
  }
  100% {
    opacity: 0;
  }
}
@keyframes zzz3 {
  0% {
    opacity: 0;
  }
  60% {
    opacity: 0;
    transform: scale(1) translate(1vh, -17.5vh);
  }
  65% {
    opacity: 1;
  }

  95% {
    opacity: 1;
    transform: scale(1.1) translate(1vh, -17.5vh);
  }
  100% {
    opacity: 0;
    transform: scale(1.1) translate(1vh, -17.5vh);
  }
}
@keyframes scene_buttons_enter {
  0% {
    height: 0;
    transform: scale(0);
  }
}
@keyframes scene_buttons_leave {
  100% {
    transform: scale(0);
    height: 0;
    // width:0;
  }
}
@keyframes shrink {
  100% {
    height: 0;
  }
}
@keyframes grow {
  0% {
    height: 0;
  }
}
@keyframes sleeping {
  50% {
    transform: scaleY(1.07);
  }
}

@keyframes idle {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2.5vh);
  }
  100% {
    transform: translateY(0);
  }
}

@media only screen and (min-width: 370px) {
  #headbox {
    .zzz {
      top: 19vh;
      right: 20vw;
    }
  }
}
@media only screen and (min-width: 450px) {
  #headbox {
    .zzz {
      top: 19vh;
      right: 20vw;
    }
  }
}
@media only screen and (min-width: 505px) {
  #headbox {
    .zzz {
      // position: absolute;
      top: 19vh;
      right: 20vw;
      // color:green;
    }
  }
}
@media only screen and (min-width: 570px) {
  #headbox {
    .zzz {
      top: 19vh;
      right: 20vw;
    }
  }
}
@media only screen and (min-width: 750px) {
  #headbox {
    .zzz {
      top: 19vh;
      right: 24vw;
    }
  }
}
@media only screen and (min-width: 900px) {
  #headbox {
    .zzz {
      top: 19vh;
      right: 26vw;
    }
  }
}
@media only screen and (min-width: 1100px) {
  #headbox {
    .zzz {
      top: 19vh;
      right: 14vw;
    }
  }
}
</style>