<template>
  <div id="main-button">
    <div id="button-container">
      <transition name="button_next">
        <div
          id="button"
          v-on:click="$emit('button-click', 'content')"
          v-if="(scene == 'main' || scene == 'main_first' || scene == 'loaded') "
        >
          <transition name="button_text_next">
            <div id="button-text">
              <img src="../assets/next.png" v-if="textState !=3" />
              <img class="disabled" src="../assets/next.png" v-if="textState ==3" />
            </div>
          </transition>
        </div>
      </transition>
      
      <transition name="button_next_3d">
        <div
          id="button-3d"
          v-if="(scene == 'main' || scene == 'main_first' || scene == 'loaded') "
        ></div>
      </transition>

      <transition name="button_back">
        <div
          id="button-back"
          v-on:click="$emit('button-click', 'content')"
          v-if="scene != 'main' && scene != 'main_first' && scene != 'loaded'"
        >
          <transition name="button_text_back">
            <div id="button-text">
              <img src="../assets/next.png" />
            </div>
          </transition>
        </div>
      </transition>
      <transition name="button_back_3d">
        <div
          id="button-3d-back"
          v-if="scene != 'main' && scene != 'main_first' && scene != 'loaded'"
        ></div>
      </transition>


    </div>
  </div>
</template>

<script>
export default {
  name: "MainButton",
  props: {
    scene: {
      type: String,
      required: true,
    },
    textState: {
      type: Number,
      required: true,
    },
  },

  components: {},

  methods: {},

  data: function () {
    return {};
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";

#button-container {
  position: relative;
  z-index: 1;

  height: $mainbuttonheight;
  width: 20vh;
  // background: green;
  // border: solid 2px rgb(14, 255, 74);
  border-radius: 0.1em;

  display: flex;
  justify-content: center;
  align-items: center;

  #button {
    position: absolute;
    height: 5vh;
    width: 100%;
    transform: skew(20deg);
    background: rgba(138, 255, 138, 0.377);
    border: solid 2px rgb(14, 255, 135);
    border-radius: 0.1em;
    transition: 0.06s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      transform: translate(3px, 0.8vh) skew(20deg);
      transition: 0.06s;
    }

    #button-text {
      color: white;
      font-family: $textfont;
      font-size: 5vh;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transform: skew(-20deg);
        height: 3vh;
      }
    }
  }
  #button-3d {
    position: absolute;
    height: 5vh;
    width: 100%;
    transform: translate(3px, 0.8vh) skew(20deg);
    // top: 0.8vh;
    // left: -2px;
    z-index: -1;
    border-radius: 0.1em;
    border-bottom: solid 2px rgb(18, 163, 54);
    border-right: solid 2px rgb(18, 163, 54);
    border-left: solid 2px rgb(18, 163, 54);
  }

  #button-back {
    position: absolute;
    height: 5vh;
    width: 100%;
    transform: skew(-20deg);
    background: rgba(255, 170, 184, 0.377);
    border: solid 2px rgb(255, 14, 54);
    border-radius: 0.1em;
    transition: 0.06s;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      transform: translate(-3px, 0.8vh) skew(-20deg);
      transition: 0.06s;
    }

    #button-text {
      color: white;
      font-family: $textfont;
      font-size: 5vh;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        transform: skew(20deg) scaleX(-1);
        height: 3vh;
      }
    }
  }
  #button-3d-back {
    position: absolute;
    height: 5vh;
    width: 100%;
    transform: translate(-3px, 0.8vh) skew(-20deg);
    // top: 0.8vh;
    // left: -2px;
    z-index: -1;
    border-radius: 0.1em;
    border-bottom: solid 2px rgb(255, 14, 54);
    border-right: solid 2px rgb(255, 14, 54);
    border-left: solid 2px rgb(255, 14, 54);
    // animation:button3d_enter 0.6s;
    // transition: 1s;
  }
}
#button-container-back {
  visibility: hidden;
  position: absolute;
  //   position: relative;
  height: 7vh;
  width: 20vh;
  // background: green;
  // border: solid 2px rgb(14, 255, 74);
  border-radius: 0.1em;
  transform: skew(-20deg);
  display: flex;
  justify-content: center;
  align-items: center;
}
.disabled{
  opacity: 0.5;
}
.button_next-enter-active {
  animation: button_next_enter 0.3s;
}
.button_next-leave-active {
  animation: button_next_leave 0.3s;
}

.button_back-enter-active {
  animation: button_back_enter 0.3s;
}
.button_back-leave-active {
  animation: button_back_leave 0.3s;
}

.button_next_3d-enter-active {
  animation: button3d_next_enter 0.6s;
}
.button_next_3d-leave-active {
  animation: button_next_leave 0.3s;
}

.button_back_3d-enter-active {
  animation: button3d_back_enter 0.6s;
}
.button_back_3d-leave-active {
  animation: button_back_leave 0.3s;
}

.button_text_next-enter_active {
    height:20vh;
  animation: button_text_next_enter 0.6s;
}
.button_text_next-leave_active {
  animation: button_text_next_enter 0.6s;
}

@keyframes button_text_next_enter {
  0% {
    -webkit-transform: scaleX(-1) translateX(-100px);
    transform: scaleX(-1) translateX(-100px);
  }

  100% {
      -webkit-transform: scaleX(-1) translateX(-100px);
    transform: scaleX(-1) translateX(-100px);
  }
}

@keyframes button_next_enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes button_next_leave {
  0% {
  }
  100% {
    transform: skew(-20deg);
    background: rgba(255, 170, 184, 0.377);
    border: solid 2px rgb(255, 14, 54);
  }
}

@keyframes button_back_enter {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 0;
  }
}

@keyframes button_back_leave {
  0% {
  }
  100% {
    transform: skew(20deg);
    background: rgba(138, 255, 138, 0.377);
    border: solid 2px rgb(14, 255, 135);
  }
}

@keyframes button3d_back_enter {
  0% {
    opacity: 0;
    transform: translate(0px, 0vh) skew(-20deg);
  }
  50% {
    opacity: 0;
    transform: translate(0px, 0vh) skew(-20deg);
  }
  100% {
  }
}

@keyframes button3d_next_enter {
  0% {
    opacity: 0;
    transform: translate(0px, 0vh) skew(20deg);
  }
  50% {
    opacity: 0;
    transform: translate(0px, 0vh) skew(20deg);
  }
  100% {
  }
}
</style>