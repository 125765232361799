<template>
  <div id="contentbox">
    <transition name="content_transition_about">
      <About :scene="scene" :sceneContent="sceneContent" v-if="scene == 'about'" />
    </transition>
    <transition name="content_transition_projects">
      <Projects  :scene="scene" :sceneContent="sceneContent" v-if="scene == 'projects'" />
    </transition>
    <transition name="content_transition_contact">
      <Contact :scene="scene" :sceneContent="sceneContent" v-if="scene == 'contact'" />
    </transition>
    <transition name="text_content_transition_first">
      <Textbox :textState="textState" v-if="scene == 'main_first'" />
      <!-- <div id="text-content" v-if="scene == 'main' && scene != 'loaded'"></div> -->
    </transition>
    <transition name="text_content_transition_main">
      <Textbox :textState="textState" v-if="scene == 'main'" />
      <!-- <div id="text-content" v-if="scene == 'main' && scene != 'loaded'"></div> -->
    </transition>

    <MainButton
      :textState="textState"
      :scene="scene"
      v-if="scene != 'loading'"

      @button-click="contentButton"
    />
  </div>
</template>

<script>
import Textbox from "../components/Textbox.vue";
import Projects from "../components/Projects.vue";
import About from "../components/About.vue";
import Contact from "../components/Contact.vue";
import MainButton from "../components/MainButton.vue";
export default {
  name: "Contentbox",
  props: {
    scene: {
      type: String,
      required: true,
    },
    textState: {
      type: Number,
      required: true,
    },
    sceneContent:{
      type: Boolean,
      required:true,
    }
  },

  components: {
    Textbox,
    Projects,
    About,
    Contact,
    MainButton,
  },

  methods: {
    contentButton() {
      this.$emit('button-click', 'content');
    },
  },

  data: function () {
    return {
      tmp: true,
    };
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";
#contentbox {
  position: relative;
  min-height: $contentboxheight;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;



  .content_transition_about-enter-active {
    animation: content_enter_about $contenttransitiontime;
  }
  .content_transition_about-leave-active {
    animation: content_leave $contenttransitiontime;
  }
   .content_transition_projects-enter-active {
    animation: content_enter_projects $contenttransitiontime;
  }
  .content_transition_projects-leave-active {
    animation: content_leave $contenttransitiontime;
  }
   .content_transition_contact-enter-active {
    animation: content_enter_contact $contenttransitiontime;
  }
  .content_transition_contact-leave-active {
    animation: content_leave $contenttransitiontime;
  }

  .text_content_transition_main-enter-active {
    height: 0;
    opacity: 0;
    animation: text_content_enter_main $contenttransitiontime;
    position: absolute;
  }
  .text_content_transition_main-leave-active {
    position: absolute;
    opacity: 0;
  }

  .text_content_transition_first-enter-active {
    animation: text_content_enter_first $contenttransitiontime;
  }
  .text_content_transition_first-leave-active {
    position: absolute;
    opacity: 0;
  }


}
@keyframes content_enter_about {
  0% {
    height: $contentheightsmall;
    background: $textboxcolorbg;
    border: solid 2px $textboxcolor;
    
  }
  100% {
    background: $aboutcolorbg;
    border: solid 2px $aboutcolor;
    height: $contentheightfull;
  }
}
@keyframes content_enter_projects {
  0% {
    height: $contentheightsmall;
    background: $textboxcolorbg;
    border: solid 2px $textboxcolor;
    
    
  }
  100% {
    background: $projectscolorbg;
    border: solid 2px $projectscolor;
    height: $contentheightfull;
  }
}
@keyframes content_enter_contact {
  0% {
    height: $contentheightsmall;
    background: $textboxcolorbg;
    border: solid 2px $textboxcolor;
    
  }
  100% {
    background: $contactcolorbg;
    border: solid 2px $contactcolor;
    height: $contentheightfull;
  }
}
@keyframes content_leave {
  100% {
    height: $contentheightsmall;
    background: $textboxcolorbg;
    border: solid 2px $textboxcolor;
    // opacity:0;
  }
}
@keyframes text_content_enter_main {
  0% {
    position: absolute;
    bottom:$mainbuttonheight;
    background:rgba(245, 222, 179, 0);
    // top:0;
    // height: 0;
    height: $contentheightfull;
    opacity: 0;
  }
  100% {
    position: absolute;
    opacity: 1;
      background:rgba(245, 222, 179, 0);
       bottom:$mainbuttonheight;
    height: $contentheightsmall;
    // top:0;
    // height: $contentheightsmall;
    // opacity: 1;
  }
}
@keyframes text_content_enter_first {
  0% {
    opacity: 0;
    height: 0;
    // width: 0;
    // height: 0;
    // transform: translateY(-$contentheightsmall);
    transform: translateY(-$contentheightsmall) scaleY(0);
  }
  100% {
    // top: 0;
    opacity: 1;
  }
}

@keyframes grow {
  100% {
    height: $contentboxheightfull;
  }
}
</style>