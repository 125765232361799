<template>
  <div id="about-content">
    <transition name="about_transition">
      <div id="scene-transition" v-if="sceneContent && transitionHelper">
        <div id="about-page">
          <div id="about-title">
            <img id="photo" src="../assets/me.png" />
            <div id="name-container">
              <div id="name">Nikolaj Boiko</div>
              <div id="info">Webdeveloper</div>
            </div>
          </div>

          <div id="about-message">
            <div id="about-message-arrow"> <img src="../assets/tinyarrow.png" /></div>
            Hi, <br />
            my name is Nikolaj, though most people just call me Niko.
            <br />
            I'm passionate about creating and learning, especially in web development. My expertise lies in PHP and
            Laravel, but I also enjoy exploring other technologies such as Vue, React, and Python.<br />
            Describing myself is one of my biggest weakness so here are a few quick facts about me:
          </div>

          <div id="quickfacts">QUICK FACTS</div>
          <div id="facts">
            <div id="fact-container">

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 1">
                  <img src="../assets/facts/electronics.png" />
                  <div class="fact-number">No.1</div>
                  <div class="fact-text">
                    researches and compares electronics way to intensly before
                    buying them
                  </div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 2">
                  <img src="../assets/facts/todo.png" />
                  <div class="fact-number">No.2</div>
                  <div class="fact-text">likes To-Do lists</div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 3">
                  <img src="../assets/facts/css.png" />
                  <div class="fact-number">No.3</div>
                  <div class="fact-text">gets annoyed by CSS</div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 4">
                  <img src="../assets/facts/drawing.png" />
                  <div class="fact-number">No.4</div>
                  <div class="fact-text">likes to draw</div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 5">
                  <img src="../assets/facts/website.png" />
                  <div class="fact-number">No.5</div>
                  <div class="fact-text">creator of this website</div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 6">
                  <img src="../assets/facts/wizard.png" />
                  <div class="fact-number">No.6</div>
                  <div class="fact-text">wants to be a wizard</div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 7">
                  <img src="../assets/facts/oderso.png" />
                  <div class="fact-number">No.7</div>
                  <div class="fact-text">says "oder so" alot</div>
                </div>
              </transition>

              <transition name="fact_transition">
                <div class="fact" v-if="fact == 8">
                  <img src="../assets/facts/water.png" />
                  <div class="fact-number">No.8</div>
                  <div class="fact-text">consists of about 60% H2O</div>
                </div>
              </transition>








              <div id="facts-arrows">
                <div class="arrow-container" v-on:click="factswipe('last')">
                  <div id="arrow-left">
                    <img src="../assets/next.png" />
                  </div>
                  <div id="arrow-left3d"></div>
                </div>
                <div class="arrow-container" v-on:click="factswipe('next')">
                  <div id="arrow-right">
                    <img src="../assets/next.png" />
                  </div>
                  <div id="arrow-left3d"></div>
                </div>

                <!-- <div id="arrow-right" class="arrow">
                  <img src="../assets/next.png" />
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="about_3d_transistion">
      <div id="about-content-3d"></div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "About",
  props: {
    scene: {
      type: String,
      required: true,
    },
    sceneContent: {
      type: Boolean,
      required: true,
    },
  },

  components: {},

  methods: {
    factswipe(direction) {
      console.log("aaa");
      if (direction == "next") {
        if (this.fact == 8) {
          this.fact = 1;
        } else {
          this.fact = this.fact + 1;
        }
      }

      if (direction == "last") {
        if (this.fact == 1) {
          this.fact = 8;
        } else {
          this.fact = this.fact - 1;
        }
      }
    },
  },

  data: function () {
    return {
      transitionHelper: false,
      fact: 1,
    };
  },
  mounted() {
    this.transitionHelper = true;
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";

#about-content {
  position: relative;
  height: $contentheightfull;
  width: 100%;
  background: $aboutcolorbg;
  border: solid 2px $aboutcolor;
  border-radius: 0.5em;

  #about-page {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    #about-title {
      position: relative;
      margin-top: 3vh;

      #photo {
        position: absolute;
        z-index: 2;
        height: 23vh;
        top: -5vh;
        left: -3vh;
      }

      #name-container {
        position: relative;
        z-index: 1;
        // top: 6vh;
        // width: 50vh;
        height: 13vh;
        // padding-top: 2vh;
        // padding-bottom: 2.6vh;
        padding-right: 2vh;
        padding-left: 19vh;
        border-radius: 100em;
        // left: 8.8vh;
        color: white;
        background: rgba(0, 0, 0, 0.425);
        border: 2px solid $aboutcolor;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        #name {
          font-family: $titlefont;
          font-size: 3.5vh;
        }

        #info {
          font-family: $textfont;
          font-size: 1.6vh;
          margin-left: -1vh;
        }
      }
    }

    #about-message {
      position: relative;
      border-radius: 0.6em;
      margin: 2vh;
      margin-top: 3.5vh;
      padding: 2vh;
      padding-left: 3vh;
      padding-right: 3vh;
      font-size: 1.7vh;
      color: white;
      text-align: left;
      background: rgba(0, 0, 0, 0.425);
      border: 1px solid $aboutcolor;
      font-family: $textfont;

      // padding-top: 20vh;
      #about-message-arrow {
        position: absolute;
        top: -2vh;
        left: calc(50% - 1.5vh);
        width: 3vh;
        // background: rgba(0, 0, 0, 0.425);
        // border: 1px solid $aboutcolor;

        img {
          width: 3vh;
        }
      }
    }

    #quickfacts {
      font-family: $titlefont;
      font-size: 2.6vh;
      color: white;
      margin-bottom: 1vh;
    }

    #facts {
      #fact-container {
        position: relative;
        width: 38vh;
        height: 30vh;
        background: rgba(0, 0, 0, 0.425);
        border: 1px solid $aboutcolor;
        border-radius: 0.6em;

        .fact {
          height: 22.9vh;
          margin-bottom: 1vh;

          img {
            margin-top: 1vh;
            height: 15vh;
          }

          .fact-number {
            font-family: $titlefont;
            font-size: 2.5vh;
            color: white;
          }

          .fact-text {
            margin-right: 2vh;
            margin-left: 2vh;
            font-size: 1.5vh;
            font-family: $textfont;
            color: white;
          }
        }

        #facts-arrows {
          bottom: 1vh;
          width: 28vh;
          left: calc(50% - 14vh);
          position: absolute;
          display: flex;
          justify-content: center;
          // margin:3vh;

          .arrow-container {
            position: relative;
            width: 14vh;
            height: 5vh;
            transform: skew(20deg);
            // margin-bottom:4vh;
          }

          .arrow {
            position: relative;
            height: 5vh;
            width: 100%;

            // margin-right: 0.5vh;
            // margin-left: 0.5vh;
            // width: 14vh;
            // height: 2.5vh;
            // width: 38%;
            background: rgba(255, 237, 179, 0.233);
            border: 1px solid $aboutcolor;
            display: flex;
            justify-content: center;
            align-items: center;
            // height: 2.5vh;

            &:hover {
              cursor: pointer;
              transform: translate(3px, 0.8vh);
              transition: 0.06s;
            }

            // img {
            //   position: absolute;
            //   // padding: 1vh;
            //   // margin-top: -0.2vh;
            //   // margin-bottom: -0.8vh;
            //   height: 2.5vh;
            // }
          }

          #arrow-left {
            position: relative;
            height: 5vh;
            width: 100%;
            background: rgba(255, 237, 179, 0.233);
            border: 1px solid $aboutcolor;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
              transform: translate(0px, 0.8vh);
              transition: 0.06s;
            }

            img {
              transform: skew(-20deg) scaleX(-1);
              height: 2.5vh;
            }
          }

          #arrow-right {
            position: relative;
            height: 5vh;
            width: 100%;
            background: rgba(255, 237, 179, 0.233);
            border: 1px solid $aboutcolor;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
              cursor: pointer;
              transform: translate(0px, 0.8vh);
              transition: 0.06s;
            }

            img {
              transform: skew(-20deg);
              height: 2.5vh;
            }
          }

          #arrow-left3d {
            position: absolute;
            height: 2.5vh;
            width: 14vh;
            transform: translate(0vh, 1.3vh);
            top: 2vh;
            // left: -2px;
            z-index: -1;
            border-radius: 0.1em;
            border-bottom: solid 1px $aboutcolor;
            border-right: solid 1px $aboutcolor;
            border-left: solid 1px $aboutcolor;
          }
        }
      }
    }
  }

  #about-content-3d {
    position: absolute;
    bottom: -0.8vh;
    height: $contentheightsmall;
    width: 100%;
    border-radius: 0.5em;
    z-index: -1;
    border-bottom: solid 2px $aboutcolor;
    animation: enter_about3d 0.6s;
  }

  .about_transition-enter-active {
    opacity: 0;
    animation: scene_content_enter 0.2s 0.2s;
  }

  .about_transition-leave-active {
    animation: scene_content_leave 0.25s;
  }

  .about_content_transition-enter-active {
    opacity: 0;
    animation: project_content_enter 0.3s;
  }

  .about_content_transition-leave-active {
    animation: project_content_leave 0.3s;
  }

  .fact_transition-enter-active {
    opacity: 0;
    position: absolute;
    // left:6vh ;
    width: 100%;
    animation: fact_enter 0.2s 0.2s;
  }

  .fact_transition-leave-active {
    animation: fact_leave 0.2s;
  }

  @keyframes fact_enter {
    0% {

      position: relative;
      transform: scaleX(0);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes fact_leave {
    0% {}

    100% {
      transform: scaleX(0);
      opacity: 0;
    }
  }

  @keyframes project_content_leave {
    0% {}

    100% {
      height: 0;
      transform: scaleY(0) translateY(-20vh);
    }
  }

  @keyframes project_content_enter {
    0% {
      height: 0;
      transform: scaleY(0) translateY(-20vh);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes scene_content_leave {
    0% {
      // border: solid 3px white;
    }

    100% {
      //  height:0;
      transform: scaleY(0) scaleX(1.1);
    }
  }

  @keyframes scene_content_enter {
    0% {
      transform: scaleY(0) scaleX(1.1);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes enter_about3d {
    0% {
      border-bottom: solid 2px $textboxcolor;
    }

    100% {
      border-bottom: solid 2px $aboutcolor;
    }
  }
}
</style>