<template>
  <div id="contact-content">
    <transition name="contact_transition">
      <div id="scene-transition" v-if="sceneContent && transitionHelper">
        <transition name="contact_form_transition">
          <div id="contact-form" v-if="!impressum && !mailSent && !error">
            <h1>CONTACT</h1>
            <div class="contact-wrapper">
              <div class="contact-item">
                <a href="mailto:nikolaj-boiko@web.de">
                  <img class="contact-icon" width="50" src="../assets/mail.png">
                </a>
                
                nikolaj-boiko@web.de
              </div>
              <div class="contact-item">
                <a href="https://www.linkedin.com/in/nikolaj-boiko-178189218/" target="_blank">
                  <img class="contact-icon" width="50" src="../assets/linkedin.png">
                </a>
                Nikolaj Boiko
              </div>
            </div>



<!--            <div id="form-info">-->
<!--              <div>-->
<!--                {{ forminfo }}-->
<!--              </div>-->
<!--            </div>-->
<!--            <input-->
<!--              type="text"-->
<!--              id="email"-->
<!--              class="input"-->
<!--              placeholder="E-Mail"-->
<!--              v-model="mail.email"-->
<!--              required-->
<!--            />-->

<!--            <input-->
<!--              type="text"-->
<!--              id="subject"-->
<!--              class="input"-->
<!--              placeholder="Subject"-->
<!--              v-model="mail.subject"-->
<!--              required-->
<!--            />-->

<!--            <textarea-->
<!--              id="message"-->
<!--              class="input"-->
<!--              placeholder="Message"-->
<!--              v-model="mail.message"-->
<!--              rows="7"-->
<!--              required-->
<!--            ></textarea>-->

<!--            &lt;!&ndash; <button v-on:click="sendMail">SEND</button> &ndash;&gt;-->
<!--            <div class="arrow-container" v-on:click="sendMail">-->
<!--              <div id="arrow-right">-->
<!--                <a>send</a>-->
<!--              </div>-->
<!--              <div id="arrow-left3d"></div>-->
<!--            </div>-->
          </div>
        </transition>

        <transition name="contact_form_transition">
          <div id="mail-sent" v-if="mailSent && !impressum">
            Your mail has been sent! <br />
            I will come back to you as soon as possible :)
          </div>
        </transition>

        <transition name="contact_form_transition">
          <div id="error" v-if="error && !impressum">
            An error has occured. Please try again later or send an E-Mail to:
            nikolaj-boiko@web.de
          </div>
        </transition>

        <transition name="contact_form_transition">
          <div id="impressum-container" v-if="impressum">
            <div id="impressum">
              <h2>Impressum</h2>

              <h3>Angaben gem&auml;&szlig; &sect; 5 TMG</h3>
              <p>
                Nikolaj Boiko<br />
                Venloer Str. 482<br />
                50825 Köln<br />
              </p>

              <h3>Kontakt</h3>
              <p>E-Mail: nikolaj-boiko@web.de</p>

              <h3>Redaktionell Verantwortlicher</h3>
              <p>
                Nikolaj Boiko<br />
                Venloer Str. 482<br />
                50825 Köln<br />
              </p>
            </div>
            <div class="arrow-container" v-on:click="impressum = !impressum">
              <div id="arrow-right-back">
                <a>back</a>
              </div>
              <div id="arrow-left3d-back"></div>
            </div>
          </div>
        </transition>

        <div
          id="impressum-button"
          v-if="!impressum"
          v-on:click="impressum = !impressum"
        >
          Impressum
        </div>
      </div>
    </transition>
    <transition name="contact_3d_transistion">
      <div id="contact-content-3d"></div>
    </transition>
  </div>
</template>

<script>
import axios from "axios";
export default {
  name: "Contact",
  props: {
    scene: {
      type: String,
      required: true,
    },
    sceneContent: {
      type: Boolean,
      required: true,
    },
  },

  components: {},

  methods: {
    ValidateEmail() {
      if (
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          this.mail.email
        )
      ) {
        return true;
      }
      // alert("You have entered an invalid email address!");
      return false;
    },
    sendMail() {
      // console.log(this.ValidateEmail());
      if (this.mail.email == "") {
        document.getElementById("email").style.border = "2px solid red";
        this.forminfo = "add E-Mail address";
        return;
      } else {
        document.getElementById("email").style.border =
          "2px solid rgb(130, 255, 113)";
        this.forminfo = "";
      }
      if (this.ValidateEmail() == false) {
        document.getElementById("email").style.border = "2px solid red";
        this.forminfo = "invalid E-Mail address";
        return;
      }
      if (this.mail.subject == "") {
        document.getElementById("subject").style.border = "2px solid red";
        this.forminfo = "add subject";
        return;
      } else {
        document.getElementById("subject").style.border =
          "2px solid rgb(130, 255, 113)";
        this.forminfo = "";
      }
      if (this.mail.message == "") {
        document.getElementById("message").style.border = "2px solid red";
        this.forminfo = "add message";
        return;
      } else {
        document.getElementById("message").style.border =
          "2px solid rgb(130, 255, 113)";
        this.forminfo = "";
      }

      // let tmpmail = {
      //   subject: this.mail.email + " Subject: " + this.mail.subject,
      //   message: this.message
      // };
      axios
        .post("https://api.outfit.recipes" + "/api/email", this.mail)
        .then(() => {
          this.mailSent = true;
        })
        .catch((error) => {
          console.log(error);
          this.mailSent = false;
          this.error = true;
        });
    },
  },

  data: function () {
    return {
      transitionHelper: false,
      mailSent: false,
      error: false,
      impressum: false,
      forminfo: "",

      mail: {
        email: "",
        subject: "",
        message: "",
      },
    };
  },

  mounted() {
    this.transitionHelper = true;
  },
};
</script>


<style lang="scss" scoped>
@import "src/styles/base.scss";

h1 {
  font-size: 60px;
}

#contact-content {
  position: relative;
  height: $contentheightfull;
  width: 100%;
  background: $contactcolorbg;
  border: solid 2px $contactcolor;
  border-radius: 0.5em;
  color: white;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;

  #form-info {
    text-align: left;
    color: rgb(255, 79, 108);
    font-size: 2vh;
    width: 40vh;
    height: 2vh;
  }
  .contact-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 20px 20px;

    .contact-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 20px 20px;
    }
    .contact-icon:hover {
      transform: rotate(8deg);
      transition: 0.2s;
      cursor: pointer;
    }
  }

  #contact-form {
    position: absolute;
    left: calc(50% - 20vh);
    margin-top: 8vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 40vh;
    h1 {
      font-family: $titlefont;
      text-shadow: rgba(53, 247, 53, 0.76) 0px 0px 1vh;
    }

    .input {
      margin-top: 1.4vh;
      width: 90%;
      color: white;
      font-size: 2.3vh;
      background-color: rgba(0, 0, 0, 0.315);
      border: 2px solid $contactcolor;
      border-radius: 0.6em;
      padding: 1vh;
    }
  }
  #mail-sent {
    margin-top: 20vh;
    width: 30vh;
    font-size: 2.3vh;
    padding: 3vh;
    background-color: rgba(0, 0, 0, 0.315);
    border: 2px solid $contactcolor;
    border-radius: 0.6em;
  }
  #error {
    margin-top: 20vh;
    width: 30vh;
    font-size: 2.3vh;
    padding: 3vh;
    background-color: rgba(0, 0, 0, 0.315);
    border: 2px solid red;
    border-radius: 0.6em;
  }
  #impressum-button {
    position: absolute;
    padding-top: 0.4vh;
    padding-bottom: 0.4vh;
    border-radius: 2em;
    bottom: 5vh;
    width: 14vh;
    left: calc(50% - 7vh);
    // background-color: black;
    background-color: rgba(0, 0, 0, 0.315);
    border: 2px solid $contactcolor;
    font-size: 2vh;
    font-family: $textfont;
    opacity: 0;
    animation: impressum_button 0.35s 0.3s forwards;
    &:hover {
      cursor: pointer;
      transform: scale(0.9);
      transition: 0.06s;
    }
  }
  @keyframes impressum_button {
    0% {
      opacity: 0;
      transform: scaleX(0);
    }
    100% {
      opacity: 1;
      transform: scaleX(1);
    }
  }
  #impressum-container {
    position: absolute;
    left: calc(50% - 19vh);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 1.8vh;
    width: 38vh;
  }
  #impressum {
    margin-top: 8vh;
    border-radius: 2em;
    // background-color: black;
    background-color: rgba(0, 0, 0, 0.315);
    border: 2px solid $contactcolor;
    padding-right: 3vh;
    padding-left: 3vh;
    padding-bottom: 3vh;
    font-family: $textfont;
  }

  .arrow-container {
    position: relative;
    width: 14vh;
    height: 5vh;
    transform: skew(20deg);
    margin-top: 2vh;
    margin-bottom: 2vh;
  }

  .arrow {
    position: relative;
    height: 5vh;
    width: 100%;
    background: rgba(255, 237, 179, 0.233);
    border: 1px solid $contactcolor;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      transform: translate(3px, 0.8vh);
      transition: 0.06s;
    }
  }
  #arrow-right {
    position: relative;
    height: 5vh;
    width: 100%;
    background: rgba(179, 255, 179, 0.233);
    border: 1px solid $contactcolor;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      transform: translate(0px, 0.8vh);
      transition: 0.06s;
    }
    a {
      font-family: $titlefont;
      font-size: 2.9vh;
      transform: skew(-20deg) translateY(-0.5vh);
      height: 2.5vh;
    }
  }

  #arrow-left3d {
    position: absolute;
    height: 2.5vh;
    width: 14vh;
    transform: translate(0vh, 1.3vh);
    top: 2vh;
    // left: -2px;
    z-index: -1;
    border-radius: 0.1em;
    border-bottom: solid 1px $contactcolor;
    border-right: solid 1px $contactcolor;
    border-left: solid 1px $contactcolor;
  }

  #arrow-right-back {
    position: relative;
    height: 3.5vh;
    width: 100%;
    background: rgba(255, 179, 179, 0.233);
    border: 1px solid rgb(253, 31, 79);
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
      transform: translate(0px, 0.8vh);
      transition: 0.06s;
    }
    a {
      font-family: $titlefont;
      font-size: 2vh;
      transform: skew(-20deg) translateY(-0.2vh);
      height: 2.5vh;
    }
  }

  #arrow-left3d-back {
    position: absolute;
    height: 2.5vh;
    width: 14vh;
    transform: translate(0vh, 0.1vh);
    top: 2vh;
    // left: -2px;
    z-index: -1;
    border-radius: 0.1em;
    border-bottom: solid 1px rgb(253, 31, 79);
    border-right: solid 1px rgb(253, 31, 79);
    border-left: solid 1px rgb(253, 31, 79);
  }

  #contact-content-3d {
    position: absolute;
    bottom: -0.8vh;
    height: $contentheightsmall;
    width: 100%;
    border-radius: 0.5em;
    z-index: -1;
    border-bottom: solid 2px $contactcolor;
    animation: enter_contact3d 0.6s;
  }

  .contact_transition-enter-active {
    opacity: 0;
    animation: scene_content_enter 0.2s 0.2s;
  }
  .contact_transition-leave-active {
    animation: scene_content_leave 0.25s;
  }

  .contact_content_transition-enter-active {
    opacity: 0;
    animation: project_content_enter 0.3s;
  }
  .contact_content_transition-leave-active {
    animation: project_content_leave 0.3s;
  }

  .contact_form_transition-enter-active {
    // position: absolute;
    // left: 3.4vh;
    opacity: 0;
    // visibility: hidden;
    // left:0;
    animation: content_form_enter 0.3s 0.3s;
  }

  .contact_form_transition-leave-active {
    animation: content_form_leave 0.3s;
  }

  @keyframes content_form_leave {
    0% {
    }

    100% {
      transform: scaleY(0);
      opacity: 0;
    }
  }
  @keyframes content_form_enter {
    0% {
      // visibility: visible;
      opacity: 0;
      transform: scaleY(0);
    }

    100% {
      opacity: 1;
    }
  }

  @keyframes project_content_leave {
    0% {
    }

    100% {
      height: 0;
      transform: scaleY(0) translateY(-20vh);
    }
  }
  @keyframes project_content_enter {
    0% {
      height: 0;
      transform: scaleY(0) translateY(-20vh);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes scene_content_leave {
    0% {
      // border: solid 3px white;
    }
    100% {
      //  height:0;
      transform: scaleY(0) scaleX(1.1);
    }
  }

  @keyframes scene_content_enter {
    0% {
      transform: scaleY(0) scaleX(1.1);
    }
    100% {
      opacity: 1;
    }
  }

  @keyframes enter_contact3d {
    0% {
      border-bottom: solid 2px $textboxcolor;
    }
    100% {
      border-bottom: solid 2px $contactcolor;
    }
  }
}
</style>