<template>
  <Main />
</template>

<script>
import Main from "./components/Main.vue";

export default {
  name: "App",
  components: {
    Main,
  },
};
</script>

<style>
body {
  background: rgb(10, 10, 26);

  padding: 0;
  margin: 0;
}
#app {
  position: fixed;
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  /* margin-top: 60px; */
}
/* @keyframes shrink {
  100% {
    height: 0vh;
  }
}
@keyframes grow {
  100% {
    height: 87vh;
    
    background: blue;
  }
} */
@keyframes sleeping {
  50% {
    transform: scaleY(1.07);
  }
}

@keyframes idle {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-2.5vh);
  }
  100% {
    transform: translateY(0);
  }
}
</style>
